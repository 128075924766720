.profile__window {
  height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.profile__window::-webkit-scrollbar {
  width: 12px;
}

.profile__window::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.profile__window::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.profile__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  background-color: var(--color-bg);
  border-radius: 0.5rem;
  border: 2px solid var(--color-border);
  box-shadow: var(--box-shadow);
}

.profile__span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.profile__span:first-child {
  width: 100%;
  justify-content: start;
  margin-top: 1rem;
  margin-left: 2rem;
}

.profile__span:last-child {
  width: 100%;
  justify-content: end;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
}

.profile__container {
  min-height: 20vh;
  min-width: 35vw;
  max-width: 50vw;
  display: inline-flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  padding: 1.5rem;
  row-gap: 1rem;
}

.profile__container--big {
  min-height: 40vh;
  max-height: 60vh;
  min-width: 40vw;
  max-width: 50vw;
  padding: 1rem;
  border: none;
}

.profile__header {
  display: flex;
  flex-direction: column;
}

.profile__title {
  display: flex;
  align-items: center;
  font-family: "Geist-SemiBold", sans-serif;
  font-size: 2rem;
  user-select: none;
  color: var(--color-text-primary);
}

.profile__description {
  font-size: 0.9rem;
  font-family: "Geist-Regular", sans-serif;
  user-select: none;
  color: var(--color-text-secondary);
  margin-bottom: 0.5rem;
}

.profile__input {
  padding: 0;
  background: transparent;
  color: var(--color-text-secondary);
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile__input label,
.profile__input--label {
  font-size: 0.85rem;
  font-family: "Geist-Medium", sans-serif;
  user-select: none;
  color: var(--color-text-primary);
}

.profile__input span {
  font-size: 0.75rem;
}

.profile__input input,
.profile__input select,
.profile__input textarea,
.profile__input--value,
.profile__input--textarea {
  border: none;
  background: none;
  color: var(--color-text-primary);
  font-family: "Geist-Regular", sans-serif;
  font-size: 0.85rem;
  outline: none;
  border: 1px solid var(--color-border);
  padding: 0.75rem;
  margin-top: 0.35rem;
  border-radius: 0.5rem;
}

.profile__input select {
  width: 100%;
}

.profile__input textarea {
  resize: none;
  min-width: 30vw;
  height: 20vh;
  overflow-y: auto;
}

.profile__input--textarea {
  width: 40vw;
  height: 41vh;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  padding: 1rem;
}

.profile__input textarea::placeholder,
.profile__input--textarea::placeholder {
  color: var(--color-text-secondary);
}

.profile__input textarea::-webkit-scrollbar,
.profile__input--textarea::-webkit-scrollbar {
  width: 12px;
}

.profile__input textarea::-webkit-scrollbar-track,
.profile__input--textarea::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.profile__input textarea::-webkit-scrollbar-thumb,
.profile__input--textarea::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.profile__input select option {
  background: var(--color-bg);
  color: var(--color-text-primary);
}

.profile__input select option:checked {
  background: var(--color-bg-body);
}

.profile__input input:focus {
  border-color: var(--color-text-secondary);
}

.profile__group {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  align-items: last baseline;
}

.profile__button {
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-text-primary);
  color: var(--color-bg);
  cursor: pointer;
  user-select: none;
  transition: 0.2 ease;
  padding: 0.6rem 1.5rem;
}

.profile__button:hover {
  opacity: 0.9;
}

.profile__button--group {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.profile__button--fetch {
  width: 30%;
}

.disabled input:hover {
  user-select: none;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Media Queries */

/* Small devices (phones) */
@media (max-width: 767px) {
  .profile__window {
    height: auto;
  }

  .profile__block {
    padding: 0.5rem;
    width: 90vw;
    margin: 1rem 0;
  }

  .profile__span:nth-child(2) {
    flex-direction: column;
    width: 100%;
  }

  .profile__container,
  .profile__container--big {
    width: 85vw;
    padding: 1rem;
    max-width: 100vw;
  }

  .profile__container {
    margin-top: 1rem;
  }

  .profile__container--big {
    margin-top: 0rem;
  }

  .profile__title {
    font-size: 1.5rem; /* Adjusted font size for smaller screens */
  }

  .profile__input textarea,
  .profile__input--textarea {
    width: 100%; /* Textarea takes full width */
  }

  .profile__group--col {
    flex-direction: column;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .profile__container {
    width: 80vw; /* More space on tablets */
    padding: 1.5rem;
  }

  .profile__container--big {
    width: 80vw; /* Consistent with the container */
  }

  .profile__title {
    font-size: 1.75rem; /* Larger font size for tablets */
  }

  .profile__input textarea,
  .profile__input--textarea {
    width: 80%; /* Slightly wider for tablets */
  }
}
