.pricing__container {
  height: 93vh;
  width: 100vw;
  text-align: center;
  background-color: var(--color-bg-body);
  color: var(--color-text-primary);
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.pricing__container::placeholder {
  color: var(--color-text-secondary);
}

.pricing__container::-webkit-scrollbar {
  width: 12px;
}

.pricing__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.pricing__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.pricing__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.pricing__title {
  font-size: 2rem;
  font-family: "Geist-SemiBold", sans-serif;
  color: var(--color-text-primary);
}

.pricing__plans {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.pricing__card {
  background: var(--color-accent);
  border-radius: 0.5rem;
  padding: 2rem;
  min-width: 20%;
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  user-select: none;
}

.pricing__plan--title {
  width: 100%;
  font-size: 1.25rem;
  font-family: "Geist-Medium", sans-serif;
  color: var(--color-text-primary);
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.pricing__plan--tag {
  font-size: 0.85rem;
  font-family: "Geist-Medium", sans-serif;
  color: var(--color-white);
  background-color: var(--color-bg-info);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.pricing__plan--subscribed {
  background-color: var(--color-bg-success);
  color: var(--color-white);
  font-size: 0.75rem;
  font-weight: "Geist-Bold", sans-serif;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.pricing__plan--contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.pricing__plan--price {
  font-size: 2rem;
  color: var(--color-text-primary);
  font-family: "Geist-Medium", sans-serif;
}

.pricing__plan--per {
  font-size: 1rem;
  color: var(--color-text-secondary);
  font-family: "Geist-Regular", sans-serif;
}

.pricing__features {
  padding: 0;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.pricing__feature {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: var(--color-text-secondary);
  display: flex;
  gap: 0.5rem;
}

.pricing__feature--icon {
  font-size: 1rem;
  color: var(--color-text-primary);
}

.pricing__btn {
  width: 100%;
  background-color: var(--color-text-primary);
  color: var(--color-bg);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Geist-Regular", sans-serif;
  margin-top: auto;
}

.pricing__btn:hover {
  background-color: var(--color-text-secondary);
}

.pricing__card--free {
  background-color: var(--color-bg);
}

.pricing__card--pro {
  background-color: var(--color-bg);
}

.pricing__card--enterprise {
  background-color: var(--color-bg);
}

.pricing__card:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease;
}

/*###################### Checkout Container ######################*/

/* .checkout__container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  border: 2px solid var(--color-border);
  border-radius: 0.5rem;
  background-color: var(--color-bg);
  position: relative;
  left: 50%;
  transform: translate(-50%, 20%);
  gap: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.checkout__title {
  text-align: center;
  font-size: 2rem;
  font-family: "Geist-Bold", sans-serif;
  color: var(--color-text-primary);
  margin-bottom: 1rem;
}

.checkout__description-box {
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: var(--color-bg);
  opacity: 0.95;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
}

.checkout__heading {
  font-size: 1.25rem;
  font-family: "Geist-SemiBold", sans-serif;
  color: var(--color-text-primary);
  margin-bottom: 0.5rem;
}

.checkout__details {
  font-size: 1rem;
  color: var(--color-text-primary);
}

.checkout__details p {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: center;
}

.checkout__details p span {
  display: flex;
  justify-content: flex-start;
}

.checkout__coin--icon {
  display: flex;
  align-self: center;
}

.checkout__terms {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--color-text-secondary);
  cursor: pointer;
}

.checkout__terms a {
  color: var(--color-text-primary);
  text-decoration: underline;
  transition: color 0.2s ease;
}

.checkout__terms a:hover {
  color: var(--color-text-secondary);
}

.checkout__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
}

.checkout__button {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.1rem;
  font-family: "Geist-Regular", sans-serif;
  color: var(--color-bg-body);
  background-color: var(--color-text-primary);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.checkout__button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.checkout__button--disabled {
  cursor: not-allowed;
}

.checkout__button--disabled:hover {
  transform: none;
}

.checkout__checkbox--icon1 {
  color: var(--color-text-primary);
} */
