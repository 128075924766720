.App {
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--color-bg-body);
  font-family: "Geist-Regular", sans-serif;
}
