@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  /* Color Variables */
  --color-border: #27272a;
  --color-bg: #09090b;
  --color-bg-body: #181818;
  --color-bg-hover: #171717e6;
  --color-accent: #202020;
  --color-text-primary: #fafafa;
  --color-text-secondary: #a1a1aa;
  --color-text-tertiary: #52525b;
  --color-text-error: #ff3333;
  --color-red: #ff0000;
  --color-green: #00ff00;
  --color-blue: #0000ff;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #9c2b2e;
  --color-bg-warning: #f39c12;
  --color-bg-info: #3498db;
  --color-bg-success: #2ecc71;

  --color-text-error: #ffffff;
  --color-text-warning: #ffffff;
  --color-text-info: #ffffff;
  --color-text-success: #ffffff;

  --color-border-error: #d32f2f;
  --color-border-warning: #e67e22;
  --color-border-info: #2980b9;
  --color-border-success: #27ae60;

  /* Gradient Variables */
  --gradient-header: linear-gradient(rgb(25, 25, 31), rgba(9, 9, 11, 1));
  --box-shadow-black: 6px 6px 12px #000000, -6px -6px 12px #242424;
}

.redColor {
  color: var(--color-border-error) !important;
}

.greenColor {
  color: var(--color-bg-success) !important;
}

.blueColor {
  color: var(--color-bg-info) !important;
}

.yellowColor {
  color: var(--color-bg-warning) !important;
}

.center {
  text-align: center;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
