.checkout__container {
  min-height: 65vh;
  width: 35vw;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  justify-content: space-around;
  padding: 2rem;
  margin: 2rem 0 0 0;
}

.checkout__title {
  font-size: 1.5rem;
  font-family: "Geist-SemiBold", sans-serif;
  user-select: none;
}

.checkout__description {
  font-size: 0.9rem;
  font-family: "Geist-Regular", sans-serif;
  user-select: none;
  color: var(--color-text-secondary);
  margin-bottom: 0.5rem;
}

.checkout__inputGroup {
  padding: 0.4rem 0;
  background: var(--color-bg);
  color: var(--color-text-secondary);
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
}

.checkout__inputGroup label {
  font-size: 0.85rem;
  font-family: "Geist-Medium", sans-serif;
  user-select: none;
}

.checkout__inputGroup input {
  border: none;
  background: none;
  color: var(--color-text-primary);
  font-family: "Geist-Regular", sans-serif;
  outline: none;
  border: 1px solid var(--color-border);
  padding: 0.75rem;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}

.checkout__inputGroup input::placeholder,
.checkout__inputGroup input:focus {
  color: var(--color-text-secondary);
}

.checkout__button {
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-text-primary);
  color: var(--color-bg);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  padding: 0.65rem;
  margin-top: 1rem;
}

.checkout__button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.checkout__button--disabled {
  cursor: not-allowed;
}

.checkout__button--disabled:hover {
  transform: none;
}

.checkout__coin--icon {
  display: flex;
  align-self: center;
}

.checkout__checkbox {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: 1rem;
  gap: 0.25rem;
}

.checkout__checkbox a {
  color: var(--color-text-primary);
  text-decoration: underline;
  transition: color 0.2s ease;
}

.checkout__checkbox a:hover {
  color: var(--color-text-secondary);
}

