.admin__container {
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: var(--color-bg-body);
  font-family: "Geist-Medium", sans-serif;
}

.admin__header {
  display: flex;
  flex-direction: column;
}

.admin__title {
  display: flex;
  align-items: center;
  font-family: "Geist-SemiBold", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-text-primary);
  user-select: none;
}

.admin__description {
  font-size: 0.9rem;
  font-family: "Geist-Regular", sans-serif;
  user-select: none;
  color: var(--color-text-secondary);
  margin-bottom: 0.5rem;
}

.admin__stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--color-bg);
  border: 1px solid var(--color-border);
  color: var(--color-text-primary);
  box-shadow: var(--box-shadow-black);
  box-sizing: border-box;
  cursor: default;
  gap: 0.5rem;
}

.admin__stat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--color-bg);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
}

.admin__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--color-bg);
  border: 1px solid var(--color-border);
  box-shadow: var(--box-shadow-black);
  box-sizing: border-box;
  gap: 0.5rem;
  color: var(--color-text-primary);
}

.admin__input {
  width: 20rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  cursor: text;
  user-select: none;
  transition: 0.2 ease;
}

.admin__input--small {
  width: 3.5rem;
}

.admin__button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  color: var(--color-bg);
  background-color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2 ease;
}

.admin__button--small {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow-white);
}

.admin__button:checked {
  background-color: var(--color-text-secondary);
}

.admin__box {
  height: 81vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: var(--color-bg-body);
  color: var(--color-text-primary);
  font-size: 1rem;
  overflow-y: auto;
  position: relative;
  user-select: none;
}

.admin__box--coupon {
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: wrap;
  row-gap: 2rem;
  justify-content: space-around;
  align-items: start;
}

.admin__box::placeholder {
  color: var(--color-text-secondary);
}

.admin__box::-webkit-scrollbar {
  width: 12px;
}

.admin__box::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.admin__box::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.admin__box--table {
  position: relative;
}

.admin__box--tr td,
.admin__box--tr th {
  text-align: left;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
}

.admin__box--tr th {
  background-color: var(--color-accent);
}

.admin__checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  user-select: none;
}

.admin__checkbox span {
  font-size: 1rem;
  color: var(--color-text-primary);
  user-select: none;
  align-items: center;
  justify-content: center;
}

/* Coupon Table */

.admin__table {
  padding: 1rem;
  display: inline-block;
  border-radius: 0.5rem;
  background: var(--color-bg);
  border: 1px solid var(--color-border);
  box-shadow: var(--box-shadow-black);
}

.admin__table tr td {
  text-align: left;
  padding: 0.1rem;
  user-select: none;
}

.admin__status {
  display: flex;
  justify-content: flex-end;
}

.admin__table_code {
  cursor: pointer;
}

.admin__table_code span {
  padding: 0.1rem 0.5rem;
  background-color: var(--color-text-primary);
  color: var(--color-bg);
  border-radius: 0.25rem;
}

.admin__table_code span:hover {
  background-color: var(--color-text-secondary);
}

/* ChatHistory */

.admin__box--chathistory {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.75rem;
  border: none;
}

.admin__datetime {
  font-size: 0.8rem;
  color: var(--color-text-secondary);
  user-select: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.admin__datetime span {
  border: 1px solid var(--color-border);
  padding: 0.25rem;
}

.admin__history--item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--color-bg-body);
  border: 1px solid var(--color-border);
  gap: 0.5rem;
  font-family: "Geist-Medium", sans-serif;
  font-size: 1rem;
}

.admin__prompt .admin__prompt--pre {
  display: inline-block;
  padding: 0.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-accent);
  user-select: none;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  white-space: pre-wrap;
  word-break: break-word;
}

.admin__response .admin__response--pre {
  display: inline-block;
  padding: 0.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-accent);
  user-select: none;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  white-space: pre-wrap;
  word-break: break-word;
}

.admin__response p,
.admin__prompt p,
.admin__response pre,
.admin__prompt pre {
  margin: 0.1rem;
}

.admin__chat--error {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__chat--error span {
  display: inline-block;
  padding: 0.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-accent);
  user-select: none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  text-align: center;
}

.admin__select {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--color-border);
  border-radius: 0.25rem;
  color: var(--color-bg);
  background-color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2 ease;
  outline: none;
  border: none;
}

.admin__select option {
  appearance: none;
  color: var(--color-bg);
  background-color: var(--color-text-primary);
  outline: none;
  border: none;
}

.admin__select option::selection {
  background-color: var(--color-text-secondary);
}

/* Profile */
.admin__box--profile {
  height: 93vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.admin__form--container-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.admin__form--container {
  min-height: 40vh;
  min-width: 35vw;
  display: inline-block;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  justify-content: space-evenly;
  padding: 1.5rem;
  margin: 1rem 0;
}

.admin__form--input,
.admin__form--select {
  padding: 0.4rem 0;
  background: var(--color-bg);
  color: var(--color-text-secondary);
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin__form--input label,
.admin__form--select label {
  font-size: 0.85rem;
  font-family: "Geist-Medium", sans-serif;
  user-select: none;
}

.admin__form--input span {
  font-size: 0.75rem;
}

.admin__form--input input,
.admin__form--select select,
.admin__form--input textarea {
  border: none;
  background: none;
  color: var(--color-text-primary);
  font-family: "Geist-Regular", sans-serif;
  font-size: 0.85rem;
  outline: none;
  border: 1px solid var(--color-border);
  padding: 0.75rem;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}

.admin__form--input textarea {
  resize: none;
  min-width: 60vw;
  height: 20vh;
}

.admin__form--select select option {
  background: var(--color-bg);
  color: var(--color-text-primary);
}

.admin__form--select select option:checked {
  background: var(--color-bg-body);
}

.admin__form--input input:focus,
.admin__form--select select:focus,
.admin__form--input textarea:focus {
  border-color: var(--color-text-secondary);
}

.admin__form--group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.admin__form--group-address {
  align-items: last baseline;
}

.admin__form--button {
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-text-primary);
  color: var(--color-bg);
  cursor: pointer;
  user-select: none;
  transition: 0.2 ease;
  padding: 0.5rem 1rem;
}

.admin__form--button:hover {
  opacity: 0.9;
}

.admin__form--btn-grp {
  display: flex;
  justify-content: flex-end;
}

.admin__form--btn-grp button {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
}

.disabled input:hover {
  user-select: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.delete {
  background-color: var(--color-red);
  color: var(--color-white);
}

/* Email */

.admin__email {
  border: none;
  background: none;
  color: var(--color-text-primary);
  font-family: "Geist-Regular", sans-serif;
  font-size: 0.85rem;
  outline: none;
  border: 1px solid var(--color-border);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.admin__box--email {
  height: 100%;
  padding-bottom: 0rem;
}

.admin__header--span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin__header--span span {
  display: flex;
  flex-direction: column;
}

.admin__form--select-tr {
  width: 25%;
  justify-content: start;
  padding: 0rem;
}

/* Notification */

.admin__notification {
  display: flex;
  flex-direction: row;
  justify-content: justify;
  align-items: start;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  user-select: none;
  width: 100%;
}

.admin__notification:hover {
  background: var(--color-bg-hover);
  border-color: var(--color-text-tertiary);
  cursor:cell;
}

.admin__notification--checkbox {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0.25rem 1rem 0 0;
  height: 100%;
  cursor: pointer;
}

.admin__notification--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.admin__notification--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.admin__notification--title {
  font-size: 1.25rem;
  font-family: "Geist-Medium", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__notification--time {
  color: var(--color-text-secondary);
  font-size: 0.85rem;
  font-family: "Geist-Light", sans-serif;
}

.admin__notification--description {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-family: "Geist-Regular", sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  text-align: justify;
}

.admin__box--notification {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
}

/* Media Queries */

/* Small Devices (Phones) */
@media (max-width: 767px) {
  .admin__container {
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .admin__box--coupon {
    align-items: center;
    gap: 1rem;
  }

  .admin__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .admin__cover--mobile {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-evenly;
  }

  .admin__stats {
    padding: 0.5rem;
    width: 100%;
    justify-content: space-evenly;
  }

  .admin__input {
    width: 90%;
    font-size: 0.9rem;
  }

  .admin__input--small {
    width: 3.5rem;
  }

  .admin__button {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .admin__box {
    height: auto;
    width: 100%;
    padding: 0.5rem;
  }

  .admin__box--coupon {
    flex-direction: column;
    gap: 1rem;
  }

  .admin__form--container {
    min-width: 90vw;
  }

  .admin__box--mobiletb {
    display: table;
    text-wrap: nowrap;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .admin__container {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }

  .admin__title {
    font-size: 1.75rem;
  }

  .admin__stats {
    width: auto;
  }

  .admin__input {
    width: 15rem;
    font-size: 1rem;
  }

  .admin__box {
    height: auto;
    width: 90%;
  }

  .admin__box--coupon {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .admin__cover {
    flex-direction: row;
    width: 100%;
  }
}