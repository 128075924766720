.stat-card {
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  box-shadow: var(--box-shadow);
  width: 10rem;
  aspect-ratio: 1/1;
  border: 1px solid var(--color-border);
}

.stat-card:hover {
  background-color: var(--color-bg-hover);
  cursor: pointer;
  border-color: var(--color-text-secondary);
}

.stat-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.stat-info h3 {
  margin: 0.5rem 0;
}

.stat-info p {
  font-size: 1.5rem;
  font-weight: bold;
}
