.settings__container {
  min-height: 40vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  justify-content: space-around;
  padding: 2rem;
  margin: 2rem 0;
}

.settings__title {
  font-size: 1.75rem;
  font-family: "Geist-SemiBold", sans-serif;
  user-select: none;
  margin-bottom: 1.5rem;
}

.settings__section {
  margin-bottom: 1.5rem;
}

.settings__section label {
  font-size: 0.95rem;
  font-family: "Geist-Medium", sans-serif;
  user-select: none;
}

.settings__select {
  font-size: 1rem;
  padding: 0.6rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border);
  margin-top: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
}

.settings__radio-group {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.settings__radio-group label {
  margin-bottom: 0.5rem;
}

.settings__radio-group input {
  margin-right: 0.5rem;
}

.settings__button {
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-text-primary);
  color: var(--color-bg);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  padding: 0.75rem;
  width: 100%;
  margin-top: 1rem;
}

.settings__button:hover {
  opacity: 0.9;
}

/* Media Queries */

/* Small devices (phones) */
@media (max-width: 767px) {
  .settings__container {
    width: 90vw; /* Adjusted for small screens */
    padding: 1.5rem;
  }

  .settings__title {
    font-size: 1.5rem; /* Adjusted font size for smaller screens */
  }

  .settings__select {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .settings__button {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .settings__container {
    width: 60vw; /* More space on tablets */
    padding: 2rem;
  }

  .settings__title {
    font-size: 1.65rem; /* Slightly larger font for readability */
  }

  .settings__select {
    font-size: 1rem;
    padding: 0.6rem;
  }

  .settings__button {
    font-size: 1rem;
    padding: 0.75rem;
  }
}
