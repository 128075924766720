@font-face {
  font-family: "Geist-Black";
  src: url("./fonts/statics-woff/Geist-Black.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Black.ttf") format("truetype");
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: "Geist-Bold";
  src: url("./fonts/statics-woff/Geist-Bold.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Bold.ttf") format("truetype");
  font-weight: bold; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Geist-Light";
  src: url("./fonts/statics-woff/Geist-Light.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Light.ttf") format("truetype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Geist-Medium";
  src: url("./fonts/statics-woff/Geist-Medium.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Geist-Regular";
  src: url("./fonts/statics-woff/Geist-Regular.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Regular.ttf") format("truetype");
  font-weight: normal; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Geist-SemiBold";
  src: url("./fonts/statics-woff/Geist-SemiBold.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Semi-bold */
  font-style: normal;
}

@font-face {
  font-family: "Geist-Thin";
  src: url("./fonts/statics-woff/Geist-Thin.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-Thin.ttf") format("truetype");
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: "Geist-UltraBlack";
  src: url("./fonts/statics-woff/Geist-UltraBlack.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-UltraBlack.ttf") format("truetype");
  font-weight: 950; /* Ultra Black */
  font-style: normal;
}

@font-face {
  font-family: "Geist-UltraLight";
  src: url("./fonts/statics-woff/Geist-UltraLight.woff") format("woff"),
    url("./fonts/statics-ttf/Geist-UltraLight.ttf") format("truetype");
  font-weight: 200; /* Ultra Light */
  font-style: normal;
}

@font-face {
  font-family: "SamsungOne";
  src: url("./fonts/samsung-ttf/DDT-W00-Condensed-SemiBold.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}
