.theme-developer {
  --color-border: #27272a;
  --color-bg: #09090b;
  --color-bg-body: #181818;
  --color-bg-hover: #171717e6;
  --color-accent: #202020;
  --color-text-primary: #fafafa;
  --color-text-secondary: #a1a1aa;
  --color-text-tertiary: #52525b;
  --color-text-error: #ff3333;
  --color-red: #ff0000;
  --color-green: #00ff00;
  --color-blue: #0000ff;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #9c2b2e;
  --color-bg-warning: #f39c12;
  --color-bg-info: #3498db;
  --color-bg-success: #2ecc71;

  --color-text-error: #ffffff;
  --color-text-warning: #ffffff;
  --color-text-info: #ffffff;
  --color-text-success: #ffffff;

  --color-border-error: #d32f2f;
  --color-border-warning: #e67e22;
  --color-border-info: #2980b9;
  --color-border-success: #27ae60;

  /* Gradient Variables */
  --gradient-header: linear-gradient(rgb(25, 25, 31), rgba(9, 9, 11, 1));
  --box-shadow-black: 6px 6px 12px #000000, -6px -6px 12px #242424;
}

.theme-lavender {
  --color-border: #dbcdf0;
  --color-bg: #f5f0fa;
  --color-bg-body: #ece4f5;
  --color-bg-hover: #e3d9f0;
  --color-accent: #c6aee0;
  --color-text-primary: #6a4a87;
  --color-text-secondary: #8a72a3;
  --color-text-tertiary: #b5a5d4;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #a4d4b4;
  --color-blue: #9dbfba;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #c6aee0;
  --color-bg-success: #a4d4b4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #7a5e99;
  --color-text-success: #80b597;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #dbcdf0;
  --color-border-success: #c8d1ae;

  --gradient-header: linear-gradient(135deg, #e7dff3, #f5f0fa);
  --box-shadow-black: 4px 4px 12px #e2d7f1, -4px -4px 12px #ffffff;
}

.theme-pastel {
  --color-border: #f3d1dc;
  --color-bg: #fef6f9;
  --color-bg-body: #fdf0f5;
  --color-bg-hover: #f9e8ee;
  --color-accent: #f7b8d4;
  --color-text-primary: #8a648a;
  --color-text-secondary: #b27fa3;
  --color-text-tertiary: #dcbad1;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #c2e4c1;
  --color-blue: #add8f7;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #add8f7;
  --color-bg-success: #c2e4c1;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #7793c6;
  --color-text-success: #99b7a5;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #c1e0f3;
  --color-border-success: #d6e3cc;

  --gradient-header: linear-gradient(135deg, #f9dae2, #fef9fc);
  --box-shadow-black: 4px 4px 12px #f5e2ea, -4px -4px 12px #ffffff;
}

.theme-soft-blue {
  --color-border: #cce4f6;
  --color-bg: #f0f7fb;
  --color-bg-body: #e3eef7;
  --color-bg-hover: #d8e8f5;
  --color-accent: #a2c4dd;
  --color-text-primary: #4a6a87;
  --color-text-secondary: #7293a3;
  --color-text-tertiary: #b5c6d4;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #a4d4b4;
  --color-blue: #9abed1;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #a4cce5;
  --color-bg-success: #a4d4b4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #6793b3;
  --color-text-success: #80b597;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #b5d4e9;
  --color-border-success: #a4d4b4;

  --gradient-header: linear-gradient(135deg, #dceaf3, #f2f8fb);
  --box-shadow-black: 4px 4px 12px #d4e2eb, -4px -4px 12px #ffffff;
}

.theme-mint-green {
  --color-border: #cce6d6;
  --color-bg: #f0f9f3;
  --color-bg-body: #e3f3e8;
  --color-bg-hover: #d8eee2;
  --color-accent: #a4d8b7;
  --color-text-primary: #4a7b61;
  --color-text-secondary: #72a38d;
  --color-text-tertiary: #b5d1c3;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #a4d4b4;
  --color-blue: #9dcab9;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #a4d8b7;
  --color-bg-success: #a4d4b4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #67b391;
  --color-text-success: #80b597;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #b5e1d0;
  --color-border-success: #a4d4b4;

  --gradient-header: linear-gradient(135deg, #dcefe5, #f2faf6);
  --box-shadow-black: 4px 4px 12px #d4e7dc, -4px -4px 12px #ffffff;
}

.theme-yellow {
  --color-border: #f6e6b3;
  --color-bg: #fdf8e3;
  --color-bg-body: #faf3d2;
  --color-bg-hover: #f5edc7;
  --color-accent: #f0d97d;
  --color-text-primary: #8a7534;
  --color-text-secondary: #b29a5a;
  --color-text-tertiary: #dcd0a7;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #c2d4a4;
  --color-blue: #b4c5de;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #f0d97d;
  --color-bg-success: #c2d4a4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #b79c52;
  --color-text-success: #99b77f;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #f6e0a3;
  --color-border-success: #d6dfb4;

  --gradient-header: linear-gradient(135deg, #f3e6c6, #faf6e6);
  --box-shadow-black: 4px 4px 12px #ece5ca, -4px -4px 12px #ffffff;
}

.theme-soft-coral {
  --color-border: #f7cdc2;
  --color-bg: #fdf0ec;
  --color-bg-body: #fbe4de;
  --color-bg-hover: #f9dad3;
  --color-accent: #f3a490;
  --color-text-primary: #8a4f43;
  --color-text-secondary: #b27c6a;
  --color-text-tertiary: #dcb5a9;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #c2d4a4;
  --color-blue: #b4c5de;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #f3a490;
  --color-bg-success: #c2d4a4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #b37668;
  --color-text-success: #99b77f;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #f7c6bc;
  --color-border-success: #d6dfb4;

  --gradient-header: linear-gradient(135deg, #f7d9d1, #fdf2ee);
  --box-shadow-black: 4px 4px 12px #f0d6cf, -4px -4px 12px #ffffff;
}

.theme-peach {
  --color-border: #f7d1bb;
  --color-bg: #fef2e8;
  --color-bg-body: #fce8d9;
  --color-bg-hover: #f9ddcc;
  --color-accent: #f3b08a;
  --color-text-primary: #8a5c3c;
  --color-text-secondary: #b28261;
  --color-text-tertiary: #dcbfa3;
  --color-text-error: #e57373;
  --color-red: #ff6f6f;
  --color-green: #c2d4a4;
  --color-blue: #b4c5de;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-bg-error: #e57373;
  --color-bg-warning: #ffe08a;
  --color-bg-info: #f3b08a;
  --color-bg-success: #c2d4a4;

  --color-text-error: #d32f2f;
  --color-text-warning: #ffa000;
  --color-text-info: #b3795e;
  --color-text-success: #99b77f;

  --color-border-error: #ff8888;
  --color-border-warning: #f9cb8b;
  --color-border-info: #f7cbb3;
  --color-border-success: #d6dfb4;

  --gradient-header: linear-gradient(135deg, #f7e0d3, #fef6ef);
  --box-shadow-black: 4px 4px 12px #f0dfd3, -4px -4px 12px #ffffff;
}
