.switch {
    position: relative;
    display: inline-block;
    width: 1.875rem;
    height: 1.0625rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F44336;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 0.8125rem;
    width: 0.8125rem;
    left: 0.125rem;
    bottom: 0.125rem;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #4CAF50;
}

input:focus+.slider {
    box-shadow: 0 0 0.03125rem #4CAF50;
}

input:checked+.slider:before {
    -webkit-transform: translateX(0.8125rem);
    -ms-transform: translateX(0.8125rem);
    transform: translateX(0.8125rem);
}

.slider.round {
    border-radius: 1.0625rem;
}

.slider.round:before {
    border-radius: 50%;
}
