.landingpage__container {
  min-height: 93vh;
  max-height: 93vh;
  width: 100vw;
  position: relative;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.landingpage__container::placeholder {
  color: var(--color-text-secondary);
}

.landingpage__container::-webkit-scrollbar {
  display: none;
  width: 0.5rem;
}

.landingpage__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.landingpage__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 0.5rem;
  border: 3px solid var(--color-bg-body);
}

.landingpage__header {
  font-size: 2rem;
  color: var(--color-text-tertiary);
  text-align: center;
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
}

.landingpage__boardborder {
  height: 50vh;
  aspect-ratio: 16/9;
  background-color: var(--color-border);
  border-radius: 0.25rem;
  position: relative;
  left: 50%;
  transform: translate(-50%, 15%);
  box-shadow: var(--box-shadow-black);
  box-sizing: border-box;
  padding: 0.75rem;
}

.landingpage__boardborder span {
  font-family: "SamsungOne", sans-serif;
  font-size: 0.6rem;
  position: absolute;
  bottom: 0rem;
  right: 50%;
  transform: translateX(50%);
  color: var(--color-text-primary);
  line-height: 0.75rem;
}

.landingpage__boardcontent {
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  box-sizing: border-box;
  background-color: var(--color-border);
}

.landingpage__gif {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}

.landingpage__people {
  aspect-ratio: 10/2;
  width: 75vw;
  box-sizing: border-box;
  position: relative;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0px 2px 4px var(--color-text-primary));
  clip-path: inset(0 0 10px 0);
}

/* Services Section */
.landingpage__service {
  width: 80vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 10%);
  padding: 2rem;
  background-color: var(--color-bg-body);
  text-align: center;
  box-sizing: border-box;
}

/* Service Grid */
.landingpage__services__grid {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: space-evenly;
  align-items: stretch;
  box-sizing: border-box;
}

/* Service Card */
.landingpage__service__card {
  background: var(--color-bg-body);
  border-radius: 1rem;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
  border: 2px solid var(--color-border);
  user-select: none;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Service Icon */
.landingpage__service__icon {
  font-size: 2rem;
  color: var(--color-text-primary);
  margin-bottom: 1rem;
}

/* Service Title */
.landingpage__service__title {
  font-size: 1.5rem;
  font-family: "Geist-Bold", sans-serif;
  color: var(--color-text-primary);
  margin-bottom: 0.5rem;
}

/* Service Description */
.landingpage__service__description span {
  font-size: 1rem;
  color: var(--color-text-secondary);
  padding: 0 1rem;
}

/* Service Button */
.landingpage__service__button {
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-text-primary);
  color: var(--color-bg-body);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Geist-Regular", sans-serif;
}

.landingpage__service__button:hover {
  opacity: 0.9;
}

.landingpage__footer {
  height: 10vh;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
  top: 5rem;
  left: 0;
  /* background-color: aquamarine; */
}

/* Media Queries */

/* For tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .landingpage__header {
    font-size: 1.75rem;
  }

  .landingpage__boardborder {
    height: 25vh;
    aspect-ratio: 16/9;
    top: 40%;
  }

  .landingpage__people {
    filter: drop-shadow(0px 0px 5px var(--color-text-primary));
    clip-path: inset(0 5px 5px 5px);
  }
}

/* For small devices */
@media (max-width: 767px) {
  .landingpage__header {
    font-size: 1.5rem;
  }

  .landingpage__boardborder {
    height: 22.5vh;
    top: 25%;
  }

  .landingpage__people {
    width: 90vw;
    height: 10vh;
    filter: drop-shadow(0px 2px 5px var(--color-text-primary));
    clip-path: inset(0 1px 5px 1px);
  }

  .landingpage__service {
    transform: translate(-50%, 5%);
  }

  .landingpage__services__grid {
    flex-direction: column;
    gap: 2rem;
    justify-content: space-evenly;
  }
}
