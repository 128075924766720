/* General Container Styles */
.blog-list__container,
.blog-detail__container {
  background-color: var(--color-bg-body);
  color: var(--color-text-primary);
  padding: 2rem;
  box-sizing: border-box;
  max-height: 93vh;
  overflow-y: auto;
}

/* Custom Scrollbar Styles */
.blog-list__container::-webkit-scrollbar,
.blog-detail__container::-webkit-scrollbar {
  width: 12px;
}

.blog-list__container::-webkit-scrollbar-track,
.blog-detail__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.blog-list__container::-webkit-scrollbar-thumb,
.blog-detail__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

/* Header Styles */
.blog-list__header,
.blog-detail__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: "Geist-Bold", sans-serif;
  margin-bottom: 2rem;
  text-align: center;
  user-select: none;
}

/* Blog Grid Layout */
.blog-list__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Blog Item Styles */
.blog-list__item {
  background-color: var(--color-bg);
  padding: 1.5rem;
  border: 2px solid var(--color-border);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-black);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-list__item:hover {
  transform: translateY(-0.625rem);
  box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.3);
}

/* Blog Image Styles */
.blog-list__image {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.blog-list__image img {
  width: 100%;
  height: 11.25rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Blog Category Badge */
.blog-list__category {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  background-color: var(--color-accent);
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: var(--color-text-primary);
  border-radius: 0.25rem;
  font-weight: bold;
}

/* Blog Title */
.blog-list__item h2 {
  font-size: 1.5rem;
  color: var(--color-text-primary);
  margin: 0 0 0.5rem 0;
}

/* Blog Description */
.blog-list__description {
  font-size: 1rem;
  color: var(--color-text-secondary);
  margin: 0 0 1rem 0;
  flex-grow: 1;
}

/* Blog Footer Styles */
.blog-list__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
  border-top: 1px solid var(--color-border);
  margin-top: 1rem;
}

/* Publication Date */
.blog-list__date {
  color: var(--color-text-tertiary);
  font-size: 0.9rem;
}

/* "Read More" Link */
.blog-list__read-more {
  color: var(--color-text-primary);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
}

.blog-list__read-more:hover {
  text-decoration: underline;
}

/* Blog Detail Container */
.blog-detail__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Blog Detail Image */
.blog-detail__image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem; /* Adjusted margin to create space between image and date */
}

.blog-detail__image img {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 0.5rem;
}

/* Blog Detail Date - moved below image */
.blog-detail__date {
  text-align: left; /* Center align the date below the image */
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--color-text-secondary);
}

/* Blog Detail Content */
.blog-detail__content {
  padding: 2rem;
  background-color: var(--color-bg);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-black);
  width: 50vw;
  max-width: 800px;
}

.blog-detail__content h1 {
  font-size: 2.5rem;
  color: var(--color-text-primary);
  margin-bottom: 1rem;
}

.blog-detail__category {
  font-size: 1rem;
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
}

.blog-detail__content p {
  font-size: 1.2rem;
  color: var(--color-text-primary);
  line-height: 1.6;
}

/* Responsive Adjustments */

/* Small Devices (Phones) */
@media (max-width: 767px) {
  /* Custom Scrollbar Styles */
  .blog-list__container::-webkit-scrollbar,
  .blog-detail__container::-webkit-scrollbar {
    display: none;
  }

  .blog-detail__content {
    width: 80vw; /* Adjust width for smaller screens */
    padding: 1.5rem;
  }

  .blog-list__header,
  .blog-detail__header {
    font-size: 2rem; /* Smaller font for headers */
  }

  .blog-list__item h2 {
    font-size: 1.2rem; /* Adjust font size for titles */
  }

  .blog-list__description {
    font-size: 0.9rem;
  }

  .blog-detail__content p {
    font-size: 1rem; /* Adjust paragraph size */
  }

  .blog-list__grid {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .blog-detail__content {
    width: 70vw; /* Adjust width for tablets */
    padding: 2rem;
  }

  .blog-list__header,
  .blog-detail__header {
    font-size: 2.5rem;
  }

  .blog-list__item h2 {
    font-size: 1.3rem;
  }

  .blog-list__description {
    font-size: 0.95rem;
  }

  .blog-detail__content p {
    font-size: 1.1rem;
  }

  .blog-list__grid {
    grid-template-columns: repeat(
      auto-fit,
      minmax(300px, 1fr)
    ); /* Adjust grid layout for tablets */
  }
}
