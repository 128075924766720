.header__container {
  height: 7vh;
  width: 100vw;
  display: flex;
  align-items: center;
  text-align: center;
  background: var(--color-bg-hover);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

.header__title {
  font-size: 1rem;
  font-family: "Geist-SemiBold", sans-serif;
  margin-left: 1rem;
  user-select: none;
  color: var(--color-text-primary);
}

.header__button,
.header__stats {
  margin-right: 1rem;
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.header__button:hover {
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-tertiary);
}

span {
  display: inline-flex;
  flex-direction: row;
}

.header__menu--button {
  margin-right: 1rem;
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__menu--button:hover {
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-tertiary);
}

.header__menu--icon {
  font-size: 1.25rem;
}

.header__menu {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  top: 7vh;
  right: 0rem;
  background: var(--color-bg);
  border: 1px solid var(--color-border);
  z-index: 1;
  padding: 0.5rem;
  gap: 0.5rem;
  border-top: none;
  border-radius: 0.5rem;
}

.header__menu a {
  text-decoration: none;
  color: var(--color-text-primary);
  background-color: var(--color-bg);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__menu--item {
  width: 100%;
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: transparent;
  color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  text-decoration: none;
}

.header__menu--item:hover {
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-tertiary);
}

.header__notification {
  margin-right: 1rem;
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-bg);
  color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  display: flex;
}

.header__notify {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-1.9rem, -0.25rem);
  background: var(--color-bg);
  border: 1px solid var(--color-text-tertiary);
  border-radius: 1rem;
  min-width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  font-family: "Geist-Medium", sans-serif;
  color: var(--color-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  padding: 0 0.25rem;
}

@media (max-width: 767px) {
  .header__container {
    flex-direction: row;
    height: 7vh;
    padding: 0.5rem;
  }

  .header__title {
    font-size: 0.85rem;
    margin-left: 0rem;
  }

  .header__button,
  .header__stats,
  .header__menu--button {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }

  .header__menu--button {
    margin-right: 0rem;
  }

  .header__menu--icon {
    font-size: 1rem;
  }

  .header__notification {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
    display: none;
  }

  .header__notify {
    font-size: 0.65rem;
    transform: translate(-1.4rem, -0.2rem);
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header__title {
    font-size: 0.95rem;
  }

  .header__button,
  .header__stats,
  .header__menu--button {
    font-size: 0.9rem;
    padding: 0.45rem 0.9rem;
  }

  .header__menu--icon {
    font-size: 1.1rem;
  }

  .header__notification {
    font-size: 0.9rem;
    padding: 0.45rem 0.9rem;
  }

  .header__notify {
    font-size: 0.7rem;
    transform: translate(-1.6rem, -0.2rem);
  }
}

@media (min-width: 1025px) {
  .header__title {
    font-size: 1rem;
  }

  .header__button,
  .header__stats {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .header__menu--button {
    padding: 0.25rem 0.5rem;
  }

  .header__menu--icon {
    font-size: 1.25rem;
  }

  .header__notification {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .header__notify {
    font-size: 0.75rem;
    transform: translate(-2rem, -0.25rem);
  }
}
