/* FAQ Page Styles */
.faq__container {
  background-color: var(--color-bg-body);
  color: var(--color-text-primary);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 1rem;
  height: 93vh;
  overflow-y: auto;
}

/* Scrollbar for Webkit browsers (Chrome, Safari) */
.faq__container::-webkit-scrollbar {
  width: 12px;
}

.faq__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.faq__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 0.5rem;
  border: 3px solid var(--color-bg-body);
}

.faq__header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: "Geist-Bold", sans-serif;
  user-select: none;
}

.faq__section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.faq__item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: var(--color-bg);
  width: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
}

.faq__item:hover {
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-primary);
}

.faq__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  column-gap: 0.25rem;
}

.faq__answer {
  margin-top: 0.5rem;
  color: var(--color-text-secondary);
  font-size: 1rem;
  line-height: 1.5;
  display: none;
  transition: 2s ease-in-out;
}

.faq__answer.visible {
  display: block;
}

.faq__toggle-icon {
  font-size: 0.85rem;
  transition: transform 0.3s;
  user-select: none;
}

/* .faq__toggle-icon.open {
  transform: rotate(180deg);
} */

/* Media Queries */

/* Small devices (phones) */
@media (max-width: 767px) {
  .faq__container {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .faq__header {
    font-size: 1.75rem; /* Adjust header font size for smaller devices */
    margin-bottom: 1.5rem;
  }

  .faq__item {
    width: 90%; /* Make FAQ items wider for small screens */
    padding: 0.75rem;
  }

  .faq__question {
    font-size: 1.15rem; /* Reduce question font size */
  }

  .faq__answer {
    font-size: 0.9rem; /* Adjust answer font size */
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .faq__container {
    padding: 2rem;
  }

  .faq__header {
    font-size: 2.25rem; /* Adjust font size for tablets */
  }

  .faq__item {
    width: 80%; /* Make FAQ items wider for tablets */
    padding: 1rem;
  }

  .faq__question {
    font-size: 1.4rem;
  }

  .faq__answer {
    font-size: 1rem;
  }
}
