.typebox__container {
  height: auto;
  width: 50vw;
  background-color: var(--color-bg);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem 0.5rem 0 0;
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  z-index: 1;
}

.typebox__textarea {
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  margin: 1rem;
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
}

.typebox__newchat {
  height: 5vh;
  width: 5vh;
  padding: 1vh;
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  border-radius: 50%;
  margin: 2vh;
  box-sizing: border-box;
  cursor: pointer;
}

.typebox__newchat .typebox_tooltip,
.typebox__submit .typebox_tooltip {
  background-color: var(--color-text-primary);
  color: var(--color-bg);
  font-size: 0.8rem;
  padding: 0.5rem;
}

.tooltip {
  padding: 8px 10px;
  border-radius: 0.2rem;
  font-size: 10%;
}

.typebox__newchat:hover {
  background-color: var(--color-accent);
}

.typebox__textarea textarea {
  max-height: 40vh;
  width: 100%;
  line-height: 1.2rem;
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  outline: none;
  border: none;
  resize: none;
  appearance: none;
  text-decoration: none;
  font-size: 1rem;
  margin-left: 1rem;
  padding: 1.5rem 0;
  box-sizing: border-box;
  overflow: auto;
}

.typebox__textarea textarea::placeholder {
  color: var(--color-text-secondary);
}

.typebox__textarea textarea::-webkit-scrollbar {
  width: 12px;
}

.typebox__textarea textarea::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.typebox__textarea textarea::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.typebox__submit {
  height: 5vh;
  width: 5vh;
  padding: 1vh;
  background-color: var(--color-text-primary);
  color: var(--color-bg);
  border: 1px solid var(--color-border);
  border-radius: 20%;
  margin: 2vh;
  box-sizing: border-box;
  cursor: pointer;
}

.typebox__submit:hover {
  background-color: var(--color-text-secondary);
}

.typebox__icon {
  height: 3vh;
  width: 3vh;
  box-sizing: border-box;
}

.typebox__footer {
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0.8rem;
  font-size: 0.8rem;
  user-select: none;
}

.typebox__footer span:hover {
  /* text-decoration: underline; */
  cursor: pointer;
  color: var(--color-text-primary);
}

/* Media Queries */

/* Small devices (phones) */
@media (max-width: 767px) {
  .typebox__container {
    width: 95vw; /* Adjust width for small devices */
    padding: 0.1rem;
  }

  .typebox__textarea {
    margin: 0.5rem;
  }

  .typebox__textarea textarea {
    font-size: 0.85rem;
    padding: 1rem 0;
    max-height: 20vh;
    line-height: 0.85rem;
  }

  .typebox__submit,
  .typebox__newchat {
    height: 4vh;
    width: 4vh;
    padding: 0.5vh;
    margin: 0.5rem;
  }

  .typebox__icon {
    height: 3vh;
    width: 3vh;
  }

  .typebox__footer {
    font-size: 0.6rem;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .typebox__container {
    width: 100vw; /* Adjust width for tablets */
  }

  .typebox__textarea textarea {
    font-size: 1rem;
  }

  .typebox__submit,
  .typebox__newchat {
    height: 5vh;
    width: 5vh;
  }

  .typebox__icon {
    height: 3vh;
    width: 3vh;
  }
}
