.welcomebox__container {
  height: 74vh;
  width: 100vw;
  box-sizing: border-box;
  background: var(--color-bg-body);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
}

.welcomebox__container--one {
  width: 40vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  background: var(--color-bg);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}

.welcomebox__header {
  color: var(--color-text-primary);
  font-family: "Geist-Bold", sans-serif;
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
}

.welcomebox__content {
  color: var(--color-text-secondary);
  font-family: "Geist-Regular", sans-serif;
}

.welcomebox__content p {
  margin-top: 0.75rem;
  font-size: 0.9rem;
}

.welcomebox__container--two {
  width: 50vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  gap: 1rem;
  margin-bottom: 2rem;
}

.welcomebox__prompts {
  color: var(--color-text-primary);
  padding: 1rem;
  background-color: var(--color-bg);
  border-radius: 0.5rem;
  border: 2px solid var(--color-border);
  user-select: none;
  cursor: pointer;
}

.welcomebox__prompts:hover {
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-tertiary);
}

@media (max-width: 767px) {
  .welcomebox__container {
    width: 90vw;
    padding: 1.5rem;
  }

  .welcomebox__header {
    font-size: 1.1rem;
  }

  .welcomebox__content p {
    font-size: 0.85rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .welcomebox__container {
    width: 70vw;
    padding: 2rem;
  }

  .welcomebox__header {
    font-size: 1.2rem;
  }

  .welcomebox__content p {
    font-size: 0.9rem;
  }
}
