.tutorials__container {
  background-color: var(--color-bg-body);
  color: var(--color-text-primary);
  padding: 2rem;
  box-sizing: border-box;
  max-height: 93vh;
  overflow-y: auto;
}

.tutorials__container::-webkit-scrollbar {
  width: 12px;
}

.tutorials__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.tutorials__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.tutorials__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 3rem;
  font-family: "Geist-Bold", sans-serif;
  margin-bottom: 2rem;
  user-select: none;
}

.tutorials__search {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 2px solid var(--color-border);
  width: 25vw;
}

.tutorials__list {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.tutorials__item {
  background-color: var(--color-bg);
  padding: 2rem;
  border: 2px solid var(--color-border);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-black);
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}

.tutorials__item:hover {
  transform: translateY(-0.625rem);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.tutorials__item h2 {
  color: var(--color-text-primary);
  font-size: 1.5rem;
  margin: 0;
}

.tutorials__item strong {
  color: var(--color-text-primary);
  font-size: 1rem;
}

.tutorials__item p {
  color: var(--color-text-secondary);
  font-size: 1rem;
}

.tutorials__video {
  margin-top: 1rem;
}

iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  box-shadow: var(--box-shadow-black);
  border-radius: 0.25rem;
}
