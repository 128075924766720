.adminpanel {
  height: 93vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--color-bg-body);
}

.adminpanel__container {
  max-height: 93vh;
  position: absolute;
  right: 0;
  background-color: var(--color-bg-body);
  overflow-y: auto;
  width: 95vw;
}

.adminpanel__container::-webkit-scrollbar {
  width: 12px;
}

.adminpanel__container::-webkit-scrollbar-track {
  background: var(--color-bg-body);
}

.adminpanel__container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-body);
}

.adminpanel__container--open {
  width: 87vw;
}

.adminpanel__sidebar {
  height: 93vh;
  position: absolute;
  top: 0;
  left: 0;
}

.adminpanel__sidebar--open {
  width: 13vw;
}

.adminpanel__sidebar--closed {
  width: 5vw;
}

.adminpanel__menu--content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.adminpanel__menu--content-btn {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 4vw;
  height: 100%;
  background-color: var(--color-bg-body);
  border-right: 1px solid var(--color-border);
}

.adminpanel__menu--button {
  user-select: none;
  cursor: pointer;
  padding: 0.5rem;
  margin: 1rem 1rem 0 1rem;
  color: var(--color-bg-body);
  background-color: var(--color-text-primary);
  font-size: 1.5rem;
}

.adminpanel__sidebar--content {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.adminpanel__sidebar--content-btn {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: 12vw;
  background-color: var(--color-bg-body);
  border-right: 1px solid var(--color-border);
}

.adminpanel__menu--item {
  height: 1.5rem;
  padding: 0.5rem;
  margin: 1rem 1rem 0 1rem;
  background-color: var(--color-text-primary);
  border-radius: 0.25rem;
  box-sizing: content-box;
  font-size: 1.1rem;
  user-select: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: var(--color-bg);
}

.adminpanel__menu--item:hover {
  background-color: var(--color-text-secondary);
}

.adminpanel__sidebar--toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-primary);
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  width: 1vw;
}

.adminpanel__sidebar--toggle-icon {
  font-size: 1rem;
  background-color: var(--color-bg-body);
  color: var(--color-bg-body);
  padding: 0.5rem 0;
  text-align: center;
}

.adminpanel__sidebar--toggle-icon:hover {
  background-color: var(--color-accent);
  color: var(--color-text-primary);
}

.adminpanel__stats--section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 1rem;
  flex-wrap: wrap;
}

.adminpanel__feedback--section {
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  background-color: var(--color-bg);
  box-sizing: border-box;
  border: 1px solid var(--color-border);
}

.adminpanel__feedback--title {
  font-size: 2.5rem;
  color: var(--color-text-primary);
}

.adminpanel__feedback--content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  overflow-y: auto;
}

.adminpanel__feedback--content::-webkit-scrollbar {
  display: none;
}

.adminpanel__feedback--item {
  display: flex;
  flex-direction: column;
  color: var(--color-text-primary);
  background-color: var(--color-bg);
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease;
  user-select: none;
  padding-top: 0.5rem;
  min-width: 18vw;
}

.adminpanel__feedback--item:hover {
  /* transform: translateY(-5px); */
  background-color: var(--color-bg-hover);
  border-color: var(--color-text-primary);
  cursor: default;
}

.adminpanel__feedback--username {
  font-size: 0.85rem;
  color: var(--color-text-tertiary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.adminpanel__feedback--star {
  font-size: 1rem;
  color: var(--color-text-primary);
}

.adminpanel__feedback--text {
  font-size: 1.25rem;
  color: var(--color-text-secondary);
}

.adminpanel__feedback--no {
  font-size: 1rem;
  color: var(--color-text-primary);
  background-color: var(--color-accent);
  text-align: center;
  padding: 1rem;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-text-tertiary);
  user-select: none;
}

/* Media Queries */

/* Small Devices (Phones) */
@media (max-width: 767px) {
  .adminpanel {
    flex-direction: column;
  }

  .adminpanel__sidebar--open,
  .adminpanel__sidebar--closed {
    width: 100vw;
    height: 7vh;
    position: relative; /* Adjust positioning to make sidebar horizontal */
  }
  .adminpanel__sidebar--toggle {
    display: none; /* Hide the toggle button */
  }

  .adminpanel__menu--content {
    flex-direction: row; /* Change to row to display the menu items horizontally */
    width: 100vw;
    height: 7vh;
    justify-content: space-evenly;
  }

  .adminpanel__container {
    width: 100vw;
    top: 7vh; /* Push content below the sidebar */
  }

  .adminpanel__container--open {
    width: 100vw;
  }

  .adminpanel__sidebar--content,
  .adminpanel__menu--button:first-child {
    display: none; /* Hide the sidebar content */
  }

  .adminpanel__menu--content-btn {
    width: 100%;
    flex-direction: row; /* Align buttons horizontally */
    justify-content: space-evenly;
  }

  .adminpanel__menu--button {
    font-size: 1rem;
    margin: 1rem;
  }

  .adminpanel__stats--section {
    flex-direction: column;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .adminpanel__container {
    width: 90vw;
  }

  .adminpanel__container--open {
    width: 80vw;
  }

  .adminpanel__sidebar--open {
    width: 15vw;
  }

  .adminpanel__sidebar--closed {
    width: 7vw;
  }

  .adminpanel__menu--content-btn {
    width: 5vw;
  }

  .adminpanel__menu--button {
    font-size: 1.25rem;
  }

  .adminpanel__menu--item {
    font-size: 1rem;
  }

  .adminpanel__stats--section {
    flex-direction: row;
    justify-content: space-around;
  }
}

/* Large Screens */
@media (min-width: 1025px) {
  .adminpanel__container {
    width: 95vw;
  }

  .adminpanel__container--open {
    width: 87vw;
  }

  .adminpanel__sidebar--open {
    width: 13vw;
  }

  .adminpanel__sidebar--closed {
    width: 5vw;
  }

  .adminpanel__menu--content-btn {
    width: 4vw;
  }

  .adminpanel__menu--button {
    font-size: 1.5rem;
  }

  .adminpanel__menu--item {
    font-size: 1.1rem;
  }
}
