.outputbox__container {
  max-height: 74vh;
  width: 50vw;
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
}

.outputbox__container::-webkit-scrollbar {
  display: none;
}

.outputbox__pr {
  display: flex;
  flex-direction: row;
  font-family: "Geist-Regular", sans-serif;
  border-bottom: 1px solid var(--color-border);
}

.outputbox__image {
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1/1;
  margin: 1.25rem;
  color: var(--color-text-primary);
  border-radius: 100%;
  border: 1px solid var(--color-border);
}

.outputbox__textspace {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color-text-primary);
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 1rem 0;
  white-space: pre-wrap;
}

/* .outputbox__textspace_output {
  padding: 0;
} */

.loader {
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1/1;
}

/* Media Queries */

/* Small devices (phones) */
/* @media (max-width: 767px) {
  .outputbox__container {
    max-height: 80vh;
    width: 95vw;
  }

  .outputbox__content span {
    font-size: 0.8rem;
  }

  .outputbox__image {
    height: 15px;
    width: 15px;
    margin: 1rem 0.5rem;
    margin-left: 0;
  }

  .outputbox__textspace {
    padding: 0.75rem 0;
  }

  .outputbox__textspace p {
    margin: 0;
  }
} */

/* Tablets */
/* @media (min-width: 768px) and (max-width: 1024px) {
  .outputbox__container {
    width: 80vw;
  }

  .outputbox__image {
    height: 18px;
    width: 18px;
    margin: 1.15rem;
  }
} */
