.signbox__container {
  min-height: 35vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-text-primary);
  border: 2px solid var(--color-border);
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  justify-content: space-around;
  padding: 2rem;
  margin: 2rem 0;
}

.signbox__title {
  font-size: 1.25rem;
  font-family: "Geist-SemiBold", sans-serif;
  user-select: none;
  margin-bottom: 1rem;
}

.signbox__input {
  padding: 0.4rem 0;
  background: var(--color-bg);
  color: var(--color-text-secondary);
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
}

.disabled-input {
  user-select: none;
  cursor: not-allowed;
}

.signbox__input.invalid input {
  border-color: var(--color-text-error);
}

.signbox__input label {
  font-size: 0.85rem;
  font-family: "Geist-Medium", sans-serif;
  user-select: none;
}

.signbox__input span {
  font-size: 0.75rem;
}

.signbox__input input {
  border: none;
  background: none;
  color: var(--color-text-primary);
  font-family: "Geist-Regular", sans-serif;
  font-size: 0.85rem;
  outline: none;
  border: 1px solid var(--color-border);
  padding: 0.75rem;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}

.signbox__input input:focus {
  border-color: var(--color-text-secondary);
}

.signbox__button {
  font-size: 1rem;
  font-family: "Geist-Medium", sans-serif;
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  background: var(--color-text-primary);
  color: var(--color-bg);
  cursor: pointer;
  user-select: none;
  transition: 0.2 ease;
  padding: 0.65rem;
  margin-top: 1rem;
}

.signbox__button:hover {
  opacity: 0.9;
}

.signbox__footer {
  font-size: 0.9rem;
  font-family: "Geist-Regular", sans-serif;
  user-select: none;
  text-align: center;
  color: var(--color-text-secondary);
  position: relative;
  margin: 1rem 0;
}

.signbox__footer a {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-text-secondary);
}

/* Media Queries */

/* For tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .signbox__container {
    width: 50vw;
    padding: 2rem;
  }

  .signbox__title {
    font-size: 1.5rem;
  }

  .signbox__button {
    font-size: 1.1rem;
    padding: 0.75rem;
  }
}

/* For large screens */
@media (min-width: 1025px) {
  .signbox__container {
    width: 25vw;
  }
}

/* For small devices */
@media (max-width: 767px) {
  .signbox__container {
    width: 80vw;
    padding: 1.5rem;
  }

  .signbox__title {
    font-size: 1.25rem;
  }

  .signbox__button {
    font-size: 0.95rem;
    padding: 0.6rem;
  }
}
