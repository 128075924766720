.payment__container {
  height: 93vh;
  width: 100vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  box-sizing: border-box;
}

.payment__header {
  text-align: center;
}

.payment__title {
  font-size: 2.5rem;
  font-family: "Geist-Bold", sans-serif;
  color: var(--color-text-primary);
}

.payment__info {
  border: 2px solid var(--color-border);
  background-color: var(--color-bg-body);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 50%;
  box-sizing: border-box;
}

.payment__success {
  color: var(--color-bg-success) !important;
  border-color: var(--color-border-success) !important;
}

.payment__fail {
  color: var(--color-border-error) !important;
  border-color: var(--color-border-error) !important;
}

.payment__info p {
  font-size: 1.2rem;
  font-family: "Geist-Regular", sans-serif;
  color: var(--color-text-secondary);
  margin: 0.5rem 0;
}

.payment__label {
  font-family: "Geist-Regular", sans-serif;
  color: var(--color-text-primary);
}

.payment__coin {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
}

.payment__actions {
  margin-top: 1rem;
}

.payment__button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: var(--color-bg);
  background-color: var(--color-text-primary);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Geist-Medium", sans-serif;
}

.payment__button:hover {
  background-color: var(--color-text-secondary);
  opacity: 0.9;
}

.payment__loading {
  font-size: 1.5rem;
  font-family: "Geist-Regular", sans-serif;
  color: var(--color-text-secondary);
}
